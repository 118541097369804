import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import * as React from "react"
// import carousel1 from '../../images/carousel1.jpeg';
// import carousel2 from '../../images/carousel2.jpeg';
import { useStaticQuery, graphql } from 'gatsby'
import './carrousel.css'
// import ComingSoon from '../modales/coming-soon';
import { StructuredText } from "react-datocms";
import { UseTheme } from '../../context/ThemeContext';

export default function CarrouselHome() {

  // const [modalShow, setModalShow] = React.useState(false);

  const { theme } = UseTheme()

  const data = useStaticQuery(graphql`
    query CarouselQuery {
      allDatoCmsHome {
        nodes {
          slider {
            backgroundDark {
              url
            }
            backgroundLight {
              url
            }
            title
            description {
              value
            }
            button {
              label
              destination {
                slug
              }
            }
            externalButtonCheck
            externalButton {
              label
              url
            }
          }
        }
      }
    }
    `)

  return (
    <>
      <Carousel className='carousel-banners'>
        {
          data.allDatoCmsHome.nodes[0].slider.map((slider, index) =>
            <Carousel.Item key={index}>
              {theme === 'dark' ?
                (<img alt="backgroundDark" src={slider.backgroundDark.url} />)
                :
                (<img alt="backgroundLight" src={slider.backgroundLight.url} />)
              }
              {/* <img alt="carousel1" src={slider.backgroundDark.url} /> */}
              <div className='carousel-text'>
                <h2 dangerouslySetInnerHTML={{ __html: slider.title }}></h2>
                <StructuredText data={slider.description.value} />
                {slider.externalButtonCheck ?
                  (<Button href={slider.externalButton[0].url} className='background-buttons-home'>{slider.externalButton[0].label}
                  </Button>)
                  :
                  (<Button href={slider.button[0].destination.slug} className='background-buttons-home'>{slider.button[0].label}
                  </Button>)
                }
              </div>
            </Carousel.Item>
          )
        }
      </Carousel>
    </>
  );
}
