import * as React from "react"
import './how-to-start.css'
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from "react-bootstrap"
import { UseTheme } from '../../context/ThemeContext';

const HowToStart = () => {

  const { theme } = UseTheme();

  const data = useStaticQuery(graphql`
    query HowToStartQuery {
      allDatoCmsHome {
        nodes {
          stepsToStartSection {
            title
          }
          stepsToStart {
            lightIcon {
              url
            }
            darkIcon {
              url
            }
            title
            description {
              value
            }
          }
        }
      }
    }
    `)

  return (
    <section className="how-to-start">
      <h3>{data.allDatoCmsHome.nodes[0].stepsToStartSection[0].title}</h3>
      <Row>
        <Col xs={12} md={4}>
          {theme === 'dark' ?
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[0].darkIcon.url} />)
            :
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[0].lightIcon.url} />)
          }
          <h4>{data.allDatoCmsHome.nodes[0].stepsToStart[0].title}</h4>
          <p>{data.allDatoCmsHome.nodes[0].stepsToStart[0].description.value.document.children[0].children[0].value}</p>
        </Col>
        <Col xs={12} md={4}>
          {theme === 'dark' ?
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[1].darkIcon.url} />)
            :
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[1].lightIcon.url} />)
          }
          <h4>{data.allDatoCmsHome.nodes[0].stepsToStart[1].title}</h4>
          <p>{data.allDatoCmsHome.nodes[0].stepsToStart[1].description.value.document.children[0].children[0].value}</p>
        </Col>
        <Col xs={12} md={4}>
          {theme === 'dark' ?
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[2].darkIcon.url} />)
            :
            (<img alt="first explication" src={data.allDatoCmsHome.nodes[0].stepsToStart[2].lightIcon.url} />)
          }
          <h4>{data.allDatoCmsHome.nodes[0].stepsToStart[2].title}</h4>
          <p>{data.allDatoCmsHome.nodes[0].stepsToStart[2].description.value.document.children[0].children[0].value}</p>
        </Col>
      </Row>
    </section>
  );
}
export default HowToStart;