import * as React from "react"
import './about-dtc-description.css'
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col, Stack } from "react-bootstrap"
import { UseTheme } from '../../context/ThemeContext';
import { StructuredText } from "react-datocms";

const useAboutDtcDescription = () => {

    const { theme } = UseTheme();

    const data = useStaticQuery(graphql`
      query aboutDtcDescriptionQuery {
        allDatoCmsHome {
          nodes {
            titleAndParagraph {
                title
                paragraph
            }
          }
        }
      }
      `)

    return (
        <div className="about-dtc-description">
            <h3 className="text-center mt-4 mb-3">{data.allDatoCmsHome.nodes[0].titleAndParagraph[0].title}</h3>
            <Stack>
                {/* <StructuredText data={data.allDatoCmsHome.nodes[0].titleAndParagraph[0].paragraph} /> */}
                <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsHome.nodes[0].titleAndParagraph[0].paragraph}}>
                </p>
            </Stack>
        </div>
    )
}

export default useAboutDtcDescription