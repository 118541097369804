import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import './modales.css'
import '../../pages/styles/index.scss'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import { StructuredText } from "react-datocms";

export default function Cookies(props) {
  const [fullscreen] = useState(true);

  const data = useStaticQuery(graphql`
    query Cookies {
        allDatoCmsCookiesPage {
          nodes {
            id
            cookiesNotification {
              value
            }
            cookiesNotificationButton {
              label
              destination {
                slug
              }
            }
          }
        }
      }
    `)

  const handleClick = () => {
    localStorage.setItem('pop_status', 1);
  };


  return (
    <Modal
      {...props}
      fullscreen={fullscreen}
      className='background-transparent cookies-background'
    >
      <div className='modal-contenido background-dark cookies-modal fixed-bottom' >
        <Container>
          <Row>
            <Col xs={12} md={9}>
              <Modal.Body>
                <StructuredText data={data.allDatoCmsCookiesPage.nodes[0].cookiesNotification.value} />
              </Modal.Body>
            </Col>
            <Col xs={12} md={3}>
              <Modal.Footer>
                <Button onClick={() => { props.onHide(); handleClick()}} className='background-buttons-home'>{data.allDatoCmsCookiesPage.nodes[0].cookiesNotificationButton[0].label}</Button>
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </div>
    </Modal>
  )
}