import Card from 'react-bootstrap/Card';
import * as React from "react"
import './card-categories.css'
import { Row, Col } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"


const CardCategories = () => {
  return (

    <StaticQuery
      query={graphql`
      query CardCategoriesQuery {
        allDatoCmsHome {
          nodes {
            categoriesSection {
              title
            }
            categories {
              name
              slug
              bannerImage {
                url
              }
              coverImage {
                url
              }
            }
          }
        }
      }`}
      render={data => (
        <section>
          <h3>{data.allDatoCmsHome.nodes[0].categoriesSection[0].title}</h3>
          <Row>
            {
              data.allDatoCmsHome.nodes[0].categories.map((categories, index) =>
                <Col xs={6} sm={6} lg={4} key={index}>
                  <a href={"/category/" + categories.slug + "/#all"}>
                    <Card className='categories'>
                      <Card.Img variant="top" alt="Logo" src={categories.coverImage.url} />
                      <Card.Body>
                        <Card.Title className='title-500'>{categories.name}</Card.Title>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
              )
            }
          </Row>
        </section>)}
    />
  );
}
export default CardCategories;
