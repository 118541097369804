import React from "react"
import { Container } from "react-bootstrap"
import CardCategories from '../components/card-categories/card-categories';
import Carrousel from '../components/carrousel/carrousel';
import FeatureCollection from '../components/feature-collection/feature-collection';
import PopularDtc from '../components/popular-dtc/popular-dtc';
import HowToStart from '../components/how-to-start/how-to-start';
import AboutDtcDescription from '../components/about-dtc-description/about-dtc-description';
import { Script } from "gatsby"
import './styles/home.css'
import Seo from '../components/seo/seo'
import './styles/index.scss';
import Cookies from '../components/modales/cookies';
import { useState, useEffect } from 'react';
import { graphql } from 'gatsby'

const Home = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    let pop_status = localStorage.getItem('pop_status');
    if (!pop_status) {
      setVisible(true);
      // localStorage.setItem('pop_status', 1);
    }
    else if (pop_status !== 1) {
      localStorage.removeItem('pop_status');
      localStorage.setItem('pop_status', 1);
    }
    else {
      setVisible(true);
    }
  }, [])

  return (
    <>
      <div className="home">
        <Carrousel />
        <Container>
          <FeatureCollection />
          <PopularDtc />
          <AboutDtcDescription />
          <HowToStart />
          <CardCategories />
        </Container>
      </div>
      {visible && (<Cookies
        show={visible}
        onHide={() => setVisible(false)}
      />)}
    </>
  )
}
export default Home

export const query = graphql`
query HomePolicy {
  allDatoCmsHome {
      nodes {
        id
        seo {
          description
          title
          twitterCard
          image {
            url
          }
        }
      }
    }
  }
`

export const Head = ({ data }) => (
  <>
    <Seo title="Home Page"
      description={data.allDatoCmsHome.nodes[0].seo.description}
      image={data.allDatoCmsHome.nodes[0].seo.image.url}
      twitterCard={data.allDatoCmsHome.nodes[0].seo.twitterCard}
    />
    <Script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/23479554.js"/>
  </>
)


