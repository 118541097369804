import { Container, Row, Col } from "react-bootstrap"
import React, { useState, useEffect } from "react"
import './popular-dtc.css'
import { useStaticQuery, graphql } from 'gatsby'
import Dtc from '../dtc/dtc'
import { useMoralis } from "react-moralis";
import RingLoader from "react-spinners/RingLoader";
import Carousel from 'react-bootstrap/Carousel';

import { useDTCs } from '../../context/DTCsContext';

const PopularDTC = () => {
  const { isInitialized } = useMoralis();
  const [ getDtcs, setGetDtcs ] = useState()
  const [ popularDtcs, setPopularDtcs ] = useState([]);
  const [ favsDtcs, setFavsDtcs ] = useState([]);

  const { get_dtcs, get_favs } = useDTCs();

  const data = useStaticQuery(graphql`
  query PopularDTCQuery { 
    allDatoCmsHome {
      nodes {
        popularSection {
          title
        }
      }
    }
  }
  `)

  useEffect(() => {
    get_dtcs({ _state: [2, 3] }).then((response) => {
      setGetDtcs(response)
    })
    get_favs({ _states: [2, 3]}).then((response) => {
      setFavsDtcs(response)
      getPopularDtc();
    })
  }, [isInitialized, !favsDtcs, !getDtcs]);

  const getPopularDtc = () => {
    if(favsDtcs && getDtcs){
      let dtcFavoritesPlusDtcs = []
      dtcFavoritesPlusDtcs = [...favsDtcs, ...getDtcs]
      const unique = [...new Map(dtcFavoritesPlusDtcs.map(item => [item["id"], item])).values()]
      setPopularDtcs(unique)
      console.log(favsDtcs)
    }
  }

  return (
    <section className="section-popular-dtc">
      <h3>{data.allDatoCmsHome.nodes[0].popularSection[0].title}</h3>
      {(!popularDtcs || !getDtcs || !('attributes' in getDtcs[0])) ?
        (<Container className="loader">
          <RingLoader color="#2a4379" />
        </Container>)
        :
        (
          <Carousel>
            <Carousel.Item>
              <Row className="popular-dtc-row">{
                (popularDtcs?.slice(0, 3).map((dtc, index) =>
                  <Col className="mb-3 ps-4 pe-4" xs={12} sm={4} key={index}>
                    <Dtc src={dtc.attributes.image} name={dtc.attributes.name} collection={dtc.attributes.collection} price={dtc.attributes.price} sendTo={"/dtc-detail/" + dtc.id} />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row className="popular-dtc-row">{
                (popularDtcs?.slice(3, 6).map((dtc, index) =>
                  <Col className="mb-3 ps-4 pe-4" xs={12} sm={4} key={index}>
                    <Dtc src={dtc.attributes.image} name={dtc.attributes.name} collection={dtc.attributes.collection} price={dtc.attributes.price} sendTo={"/dtc-detail/" + dtc.id} />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row className="popular-dtc-row">{
                (popularDtcs?.slice(6, 9).map((dtc, index) =>
                  <Col className="mb-3 ps-4 pe-4" xs={12} sm={4} key={index}>
                    <Dtc src={dtc.attributes.image} name={dtc.attributes.name} collection={dtc.attributes.collection} price={dtc.attributes.price} sendTo={"/dtc-detail/" + dtc.id} />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          </Carousel>
        )
      }
    </section>
  );
}
export default PopularDTC; 