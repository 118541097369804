import * as React from "react"
import './feature-collection.css'
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col, Stack, Button } from "react-bootstrap"

const FeatureCollection = () => {

  const data = useStaticQuery(graphql`
    query FeatureCollectionQuery {
        allDatoCmsHome {
          nodes {
            featuredSection {
              title
            }
            banners {
              size
              background {
                url
              }
              alignText
              color {
                hex
              }
              title
              description {
                value
              }
              button {
                label
                destination {
                  slug
                }
              }
              externalButton {
                label
              url
              }
              externalButtonCheck
            }
          }
        }
      }
    `)

  return (
    <section className="feature-collection">
      <h3>{data.allDatoCmsHome.nodes[0].featuredSection[0].title}</h3>
      <Stack className="card-long">
        <img alt="card-long-first" src={data.allDatoCmsHome.nodes[0].banners[0].background.url} />
        <div className="text-cards-feature-collection">
          <h4>{data.allDatoCmsHome.nodes[0].banners[0].title}</h4>
          <p>{data.allDatoCmsHome.nodes[0].banners[0].description.value.document.children[0].children[0].value}</p>
          {data.allDatoCmsHome.nodes[0].banners[0].externalButtonCheck ?
            (
              <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[0].externalButton[0].url}>
                {data.allDatoCmsHome.nodes[0].banners[0].externalButton[0].label}
              </Button>
            )
            :
            (
              <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[0].button[0].destination.slug}>
                {data.allDatoCmsHome.nodes[0].banners[0].button[0].label}
              </Button>
            )
          }
        </div>
      </Stack>
      <Row>
        <Col xs={12} md={6}>
          <Stack className="card-small">
            <div className="text-cards-feature-collection">
              <img alt="card-long-second" src={data.allDatoCmsHome.nodes[0].banners[1].background.url} />
              <div className="text-cards-absolute pink">
                <h5>{data.allDatoCmsHome.nodes[0].banners[1].title}</h5>
                <p>{data.allDatoCmsHome.nodes[0].banners[1].description.value.document.children[0].children[0].value}</p>
                {data.allDatoCmsHome.nodes[0].banners[0].externalButtonCheck ?
                  (
                    <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[1].externalButton[0].url}>
                      {data.allDatoCmsHome.nodes[0].banners[1].externalButton[0].label}
                    </Button>
                  )
                  :
                  (
                    <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[1].button[0].destination.slug}>
                      {data.allDatoCmsHome.nodes[0].banners[1].button[0].label}
                    </Button>
                  )
                }
              </div>
            </div>
          </Stack>
        </Col>
        <Col xs={12} md={6}>
          <Stack className="card-small">
            <div className="text-cards-feature-collection">
              <img alt="card-long-second" src={data.allDatoCmsHome.nodes[0].banners[2].background.url} />
              <div className="text-cards-absolute blue">
                <h5>{data.allDatoCmsHome.nodes[0].banners[2].title}</h5>
                <p>{data.allDatoCmsHome.nodes[0].banners[2].description.value.document.children[0].children[0].value}</p>
                {data.allDatoCmsHome.nodes[0].banners[2].externalButtonCheck ?
                  (
                    <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[2].externalButton[0].url}>
                      {data.allDatoCmsHome.nodes[0].banners[2].externalButton[0].label}
                    </Button>
                  )
                  :
                  (
                    <Button className='background-buttons-home' href={data.allDatoCmsHome.nodes[0].banners[2].button[0].destination.slug}>
                      {data.allDatoCmsHome.nodes[0].banners[2].button[0].label}
                    </Button>
                  )
                }
              </div>
            </div>
          </Stack>
        </Col>
      </Row>
    </section>
  );
}
export default FeatureCollection;